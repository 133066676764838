@import '../../scss/settings';

.root {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-16);
  margin-bottom: var(--spacing-32);
  text-align: left;
}

.root p {
  margin: 0;
  display: inline;
}

.readMore {
  text-decoration: underline;
  cursor: pointer;
}
