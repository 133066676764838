@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';
@import '../../../scss/mixins/em';

.subtitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
}

.customMessage h1,
.customMessage h2,
.customMessage h3 {
  font-weight: var(--font-weight-bold);
}

.customMessage h1 {
  font-size: var(--font-size-lg);
}

.customMessage h2 {
  font-size: var(--font-size-md);
}

.customMessage h3 {
  font-size: var(--font-size-sm);
}

.message {
  margin-bottom: var(--spacing-32);
}

.customMessage {
  margin-bottom: var(--spacing-32);
  text-align: start;
}

.customMessage.withBox {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-16);
}

.customMessage ol,
.customMessage ul {
  margin: var(--spacing-16);
  padding: 0;
}

@include respond-to(handhelds-tablets) {
  .root {
    padding-bottom: 70px;
  }
}
