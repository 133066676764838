@import '../../../../scss/settings';

.subtitle {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-md);
}

.infoMessage {
  color: var(--color-status-highlight);
  margin-top: 14px;
  font-weight: var(--font-weight-bolder);
}

.attributes {
  font-weight: var(--font-weight-bolder);
  margin-top: var(--spacing-08);
}

.actions {
  margin-top: var(--spacing-32);
}

.actions > button + button {
  margin-inline-start: var(--spacing-08);
}
