@import '../../scss/settings';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
}

.button {
  width: 100%;
  height: 3rem;
  padding-inline: 1.5rem;
  background-color: var(--color-btn--primary-bg);
  color: var(--color-btn--primary-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  text-align: center;
  justify-content: center;
}

.button:hover {
  background-color: var(--color-btn--primary-hover-bg);
}

.button:active {
  background-color: var(--color-btn--primary-active-bg);
}

.button:hover,
.button:active {
  color: var(--color-btn--primary-text);
}
