@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

$kiosk-input-height: 112px;
$kiosk-code-length: 10;
$kiosk-employee-code-length: 12;

.root {
  min-height: 240px;
  position: relative;
}

.form {
  position: relative;
}

.formTitle {
  display: block;
  color: var(--color-text-secondary);
  font-size: em(25px);
  margin-bottom: $spacing;
  text-align: center;
}

.inputContainer {
  align-items: center;
  display: inline-flex;
  height: $kiosk-input-height;
  justify-content: center;
  padding: 0 10px 0 $spacing;
  position: relative;

  &::before {
    bottom: 80px;
    content: '__________';
    font-family: 'Courier New', Courier, monospace;
    font-size: em(60px);
    height: 3px;
    left: $spacing;
    letter-spacing: 10px;
    line-height: 1;
    position: absolute;
    width: 100%;
  }
}

.employeeCode .inputContainer::before {
  content: '____________';
}

.error .inputContainer {
  background-color: var(--color-bg-error);

  &::before {
    color: var(--color-status-negative);
  }
}

.input {
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 3px;
  color: var(--color-text-secondary);
  font-family: 'Courier New', Courier, monospace;
  font-size: em(60px);
  letter-spacing: 10px;
  line-height: 1;
  outline: none;
  padding: $spacing 0;
  position: relative;
  resize: none;
  width: 46px * $kiosk-code-length;
}

.input:active,
.input:focus {
  /*
    until we rebrand this, we need to make sure active/focus state
    is not overwritten by bedrock
  */
  outline: none !important;
  box-shadow: none !important;
}

.employeeCode .input {
  width: 46px * $kiosk-employee-code-length;
}

.error .input {
  color: var(--color-status-negative);
}

.button {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $kiosk-input-height;
  width: $kiosk-input-height;
  margin-left: $spacing;
  opacity: 0;
  transition:
    opacity 0.4s,
    transform 0.3s;
  /* simulate a primary bedrock button */
  background-color: var(--color-btn--primary-bg);
  color: var(--color-btn--primary-text);
  border-radius: var(--border-radius-base);
}

.button:hover {
  background-color: var(--color-btn--primary-hover-bg);
}

.error .button:disabled {
  opacity: 0.3;
}

.valid .button {
  opacity: 1;
  transform: translate3d(130px, 0, 0);
  z-index: auto;
}

.errorMessage {
  composes: fadeIn from 'sass-loader!../../scss/_animations.scss';
  font-size: var(--font-size-md);
  margin: $spacing auto 0;
  max-width: 46px * $kiosk-code-length;
  min-width: 416px;
  text-align: center;
  width: 100%;
}

.employeeCode .errorMessage {
  max-width: 46px * $kiosk-employee-code-length;
}

.forceCode {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-large);
  margin: 70px 0;
  text-align: center;
}

.forceCode a {
  color: var(--color-text);
  cursor: pointer;
  text-decoration: underline;
}

.arrow {
  height: 48px;
  width: 48px;
}

@include respond-to(handhelds) {
  .root {
    min-height: 0;
  }

  .inputContainer,
  .button {
    height: calc($kiosk-input-height / 2);
  }

  .button {
    left: -40px;
    margin: 0;
    top: 112px;
    width: 100%;
  }

  .valid .button {
    transform: translate3d(40px, 0, 0);
  }

  .inputContainer {
    &::before {
      bottom: 30px;
      font-size: 2em;
    }
  }

  .input {
    font-size: 2em;
    width: 26px * $kiosk-code-length;
  }

  .employeeCode .input {
    width: 26px * $kiosk-employee-code-length;
  }

  .errorMessage {
    max-width: 26px * $kiosk-code-length;
    font-size: var(--font-size-base);
    min-width: 0;
  }

  .employeeCode .errorMessage {
    max-width: 26px * $kiosk-employee-code-length;
  }

  .formTitle {
    font-size: var(--font-size-large);
  }
}

/* Prevent iOS from zooming when input is focused — https://workday-dev.slack.com/archives/C0237MVHBUK/p1681382341740119 */
@supports (-webkit-touch-callout: none) {
  .inputContainer {
    font-size: var(--font-size-md) !important;
  }
}
