@import '../../scss/settings';
@import '../../scss/mixins/em';

:global(#survey-app.has-overlay) {
  filter: blur(8px);
}

.root {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  composes: fadeIn from 'sass-loader!../../scss/_animations.scss';
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 40px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: $z-index-6--kiosk-stop-overlay;
}

.title {
  font-weight: var(--font-weight-bold);
  font-size: em(35px);
}

.text {
  font-size: em(25px);
  margin-top: 70px;
  max-width: 700px;
  padding: 0 40px;

  strong {
    font-weight: normal;
  }
}

.actions {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.buttonContinue {
  margin-right: var(--spacing-32);
}
