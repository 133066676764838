@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

$gutter: 130px;
$gutter-sm: 60px;
$main-width: 570px;
$main-width-sm: 520px;

.root {
  display: flex;
  min-height: 100vh;
}

.root.isNative {
  padding-bottom: 3rem;
}

.loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.main {
  max-width: $main-width;
  width: 100%;
  margin: 0 $gutter;
  padding: var(--spacing-32) 0 var(--spacing-64) 0;
}

@media only screen and (max-width: 1200px) {
  .main {
    max-width: $main-width-sm;
    margin: 0 $gutter-sm;
  }
}

.main .anonymity {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-64);
}

@media only screen and (max-width: $main-width) {
  .main {
    margin: var(--spacing-16);
    padding: 0;
  }

  .main .anonymity {
    margin-bottom: var(--spacing-32);
  }
}

.main .anonymity svg {
  margin-inline-end: var(--spacing-08);
  color: var(--color-icon-decorative);
}

.main > h2 {
  font-size: var(--font-size-2xl);
}

.main > p {
  font-size: var(--font-size-md);
  margin-top: 0;
  margin-bottom: var(--spacing-32);
}

.main .cards {
  margin-bottom: var(--spacing-32);
}

.sidebar {
  flex: 1;
  background-color: var(--color-bg-primary);
  padding: var(--spacing-32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .sidebar {
    display: none;
  }
}

.sidebar .nextSurvey {
  display: flex;
  align-items: center;
}

.sidebar .nextSurvey a {
  margin-inline-start: var(--spacing-16);
}

.sidebar .imageContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.sidebar .imageContainer img {
  max-width: 28.75rem;
  width: 100%;
}

.sidebar .imageContainer img.smallImage {
  max-width: 18rem;
}
