@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.81;
  }
}

.fadeIn,
.fadeInOverlay {
  animation-duration: 0.4s;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInOverlay {
  animation-name: fadeInOverlay;
}

@keyframes slideInUpInfo {
  from {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpInfo {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInUpInfo;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes slideInUpComment {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpComment {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInUpComment;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInDown;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
