@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.header {
  flex: 0 0 $header-height;
  margin-top: 13px;
}

.footer {
  flex: 0 0 $header-height;
}

.wrapper {
  align-items: center;
  display: flex;
  flex: 2;
  justify-content: center;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-32);
}

.branding {
  margin-left: 15px;
  margin-right: 100px;
  text-align: center;
}

.surveyBranding {
  display: block;
  margin-bottom: 32px;
  max-width: 300px;
}

.surveyBrandingImage {
  background-size: contain;
  display: block;
  height: auto;
  width: 100%;
}

.logo {
  flex: 0 0 250px;
  margin-right: 154px;
  text-align: center;
}

[dir='rtl'] .logo {
  margin-left: 154px;
  margin-right: 0;
}

.content {
  width: $content-width;
}

.logoImage {
  max-height: 250px;
  max-width: 100%; /* needed for IE 11 */
}

.disclaimers {
  text-align: left;
}

@include respond-to(handhelds-tablets) {
  .root {
    display: block;
    height: 100vh;
    overflow-y: auto;
    padding: 20px 20px 74px;
    text-align: center;
  }

  .header {
    align-items: center;
    display: flex;
  }

  .branding {
    margin: 0;
    text-align: center;
  }

  .surveyBranding {
    max-width: 144px;
  }

  .container {
    display: block;
    min-height: 0;
  }

  .header {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .footer {
    margin-top: 40px;
  }

  .wrapper {
    flex-direction: column;
  }

  .logo {
    flex: 0 0 auto;
    margin: 0 auto 20px;
  }

  .logoImage {
    max-height: 120px;
  }

  .content {
    width: 100%;
  }

  .buttonContainer {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $z-index-1--question-action;
  }

  .buttonContainer a {
    width: 100%;
    border-radius: 0;
    justify-content: center;
  }
}

@media screen and (max-height: 650px) and (max-width: $screen-md) {
  .logo {
    display: none;
  }
}

@media screen and (min-width: $screen-md) and (max-width: 1279px) {
  .logo {
    margin-inline-end: 54px;
  }
}

@media screen and (max-height: 700px) {
  .logoImage {
    max-height: 70px;
  }
}
