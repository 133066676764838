@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.root {
  width: 100%;
}

[dir='rtl'] .root {
  direction: rtl;
}

.info {
  font-weight: var(--font-weight-bold);
}

.infoAnimated {
  composes: slideInUpInfo from 'sass-loader!../../scss/_animations.scss';
}

.questionContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--spacing-08);
}

.questionContainer button {
  margin-inline-start: var(--spacing-16);
}

.container {
  align-items: flex-start;
  display: flex;
}

.textareaContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.textarea {
  display: block;
  max-height: 200px;
  min-height: 53px;
  resize: none;
  margin-bottom: var(--spacing-08);
}

@include respond-to(handhelds-tablets) {
  .textarea {
    font-size: em(16px); // http://stackoverflow.com/a/6394497/338762
    max-height: none;
    min-height: 90px;
    width: 100%;
  }

  .questionContainer {
    display: block;
    margin-right: -85px;
    text-align: center;
  }

  .focused .container {
    margin: 0;
  }

  .focused .info {
    font-weight: var(--font-weight-bolder);
  }

  .focused .textareaContainer {
    height: 100%;
    position: relative;
  }

  .focused .questionContainer {
    display: flex;
    margin-right: 0;
    text-align: left;
  }

  .focused .textarea {
    box-shadow: none;
    display: block;
    height: 100%;
    margin: var(--spacing-16) 0;
    width: 100%;

    .focused:focus {
      background-color: transparent;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  .focused .questionContainer {
    display: none !important;
  }
}

/* stylelint-disable-next-line media-feature-name-no-vendor-prefix */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .focused .textarea {
    min-height: 50px;
    margin: var(--spacing-08) 0;
  }
}
