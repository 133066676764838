@import 'z-index';

/* lagacy spacing vars for ng */
$spacing: 20px;
$spacing-tiny: 5px;
$spacing-small: 15px;
$spacing-big: 40px;
$spacing-huge: 50px;

// Ui sizes
$header-height: 72px;
$footer-height: 72px;
$scale-size: 51px;
$scale-container-size: $scale-size * 11;
$content-width: $scale-container-size + 110px;
$system-banner-height: 55px;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//## Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//## Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//## Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//## Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//## Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//## Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//## Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//## Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//## Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
