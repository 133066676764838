// Z-index values should always be defined in _z-index.scss. This
// allows us to at a glance determine relative layers of our application and prevents bugs
// arising from arbitrary z-index values. Do not edit the z-index scale! Only add application
// scoped z-index values.

// Z-Index Scale (private vars)
// --------------------------------------------------

$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;

// Z-Index Applications
// --------------------------------------------------

$z-index-1--progress-bar: $z-index-1;
$z-index-1--question-action: $z-index-1;
$z-index-1--question-previous: $z-index-1;
$z-index-1--kiosk-info: $z-index-1;

$z-index-2--kiosk-header: $z-index-2;
$z-index-2--question-skip: $z-index-2;

$z-index-3--submit-overlay: $z-index-3;

$z-index-4--comment-focused: $z-index-3;
$z-index-4--submit: $z-index-4;

$z-index-5--system-bar: $z-index-5;

$z-index-6--kiosk-stop-overlay: $z-index-6;

$z-index-7--notification: $z-index-7;

$z-index-10--dev: $z-index-10;
