@import '../../../../scss/settings';
@import '../../../../scss/mixins/respond-to';
@import '../../../../scss/mixins/em';

.row {
  margin-bottom: var(--spacing-24);
}

.description {
  color: var(--color-text-secondary);
  font-size: var(--font-size-xs);
  margin-top: var(--spacing-04);
}

.label {
  color: var(--color-text-secondary);
  display: block;
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-xs);
  margin-bottom: var(--spacing-04);
}

@include respond-to(handhelds-tablets) {
  .actions {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    background-color: var(--color-bg-card);
  }

  .actions button {
    width: 100%;
    border-radius: 0;
    justify-content: center;
  }
}
