@import '../../../scss/settings';

.enter {
  transform: translate3d(0, 100%, 0);
}

.enter.enterActive {
  color: var(--color-status-highlight);
  transform: translate3d(0, 0, 0);
}

.exit {
  transform: translate3d(0, -100%, 0);
}

.exit.exitActive {
  color: var(--color-status-highlight);
  transform: translate3d(0, -200%, 0);
}

.enter.enterActive,
.exit.exitActive {
  transition:
    transform 0.6s ease-in-out,
    color 0.6s linear;
}
