@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  height: 100%;
  position: relative;
  font-size: 13px;

  &.submitted {
    overflow-y: auto;
  }
}

.content,
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.loading {
  height: 100vh;
}

[dir='rtl'] {
  direction: rtl;
}

.previewClose {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-text-secondary);
  width: $system-banner-height;
  height: $system-banner-height;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
