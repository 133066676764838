@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';

.activateCta {
  font-weight: var(--font-weight-bold) !important;
  font-size: var(--font-size-md) !important;
  margin-bottom: var(--spacing-16) !important;
}

.root {
  display: flex;
}

.root .learnMore {
  display: flex;
  align-items: center;
  margin-inline-start: var(--spacing-08);
}

.root .learnMore svg {
  color: var(--color-icon-decorative);
}

@include respond-to(handhelds) {
  .activateCta {
    text-align: center !important;
    font-size: var(--font-size-xs) !important;
  }

  .root {
    flex-direction: column;
    align-items: center;
  }

  .root .learnMore {
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-08);
  }
}
