@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  align-items: center;
  background-color: var(--color-bg-info);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  color: var(--color-text-primary);
  display: flex;
  font-size: var(--font-size-large);
  height: $system-banner-height;
  justify-content: center;
  left: 0;
  line-height: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-5--system-bar;
}

.positive {
  background-color: var(--color-bg-success);
  composes: slideInDown from 'sass-loader!../../scss/_animations.scss';
}

.negative {
  background-color: var(--color-bg-error);
  bottom: 0;
  composes: slideInUpInfo from 'sass-loader!../../scss/_animations.scss';
  top: auto;
}

.link {
  color: var(--color-text-primary);
  margin-left: 10px;
}

.text {
  display: inline-block;
  margin-left: 3px;
}

.bottom {
  bottom: 0;
  top: initial;
}

.bottom strong {
  display: inline-block;
  margin-right: 4px;
}

.dark {
  background: var(--color-bg-info);
}

@include respond-to(handhelds-tablets) {
  .root {
    display: none;
  }
}
