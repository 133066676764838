@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  // always use ltr on scale as per translator recommendation
  direction: ltr;
  width: 100%;
}

.choose {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

.container {
  align-items: flex-start;
  display: flex;
  margin-top: 11px;
  width: 100%;
}

.question {
  flex: 1;
  position: relative;
  width: 100%;
  padding: 0 4px;
}

.infoContainer {
  align-items: flex-end;
  display: flex;
  margin-top: 10px;
}

.legend {
  width: 100%;
}

.commentAnimated {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 4px;
  animation-name: slideInUpComment;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-delay: 0.3s;
  animation-duration: 0.65s;
  animation-fill-mode: both;
}

.legend {
  color: var(--color-status-highlight);
  display: flex;
}

.legendItem {
  display: block;
  flex: 1;
  color: var(--color-text-secondary);
  font-size: var(--font-size-sm);
}

.legendItem:last-child {
  text-align: right;
}

.button {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex: 0 0 147px;
  flex-direction: column;
  margin-left: var(--spacing-16);
  padding-top: 30px;
}

@include respond-to(handhelds-tablets) {
  .legendItem {
    text-align: left;
  }

  .commentAnimated {
    padding-right: 85px;
  }

  .container {
    min-height: 125px;
  }

  .question {
    transform: translate3d(0, 0, 0);
  }

  .button {
    margin-left: 5px;
    flex: 0 0 80px;
  }

  .focused .container {
    margin: 0;
  }

  .focused .button {
    display: none;
  }
}

@keyframes slideInUpComment {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeOut {
  animation-duration: 0.65s;
  transition: opacity 0.4s;
  transition-delay: 0.2s;
  opacity: 0;
}
