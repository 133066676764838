@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';
@import '../../../scss/mixins/em';

.root {
  animation-delay: 0.6s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: tada;
  animation-timing-function: ease-in;
  color: var(--color-text-primary);
  font-size: var(--font-size-h6);
  height: 42px;
  line-height: 1.2;
  position: absolute;
  right: -16.5px;
  text-align: right;
  text-transform: lowercase;
  top: 10px;
  width: 42px;
}

.minutes,
.estimated {
  color: var(--color-text-primary);
}

.minutes {
  align-items: flex-end;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  text-align: right;
}

.estimated {
  align-items: center;
  display: flex;
  line-height: 1;
  margin-left: -4px;
}

.transitionGroup {
  display: block;
  height: 14px;
  overflow: hidden;
}

.count {
  display: block;
  font-weight: var(--font-weight-bolder);
  font-size: em(14px);
  height: 14px;
  line-height: 1;
}

.label {
  font-weight: var(--font-weight-bolder);
  font-size: em(7px);
  max-width: 100%;
  overflow: hidden;
  text-transform: uppercase;
}

.tilde {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-base);
}

@include respond-to(handhelds-tablets) {
  .root {
    align-items: center;
    animation: none;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }

  .count,
  .label {
    font-size: em(9px);
    height: 9px;
    line-height: 1;
  }

  .transitionGroup {
    height: 9px;
  }

  .minutes {
    border-radius: 0;
    flex-direction: row;
  }
}

@keyframes tada {
  from {
    color: var(--color-status-positive);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg);
  }

  to {
    color: var(--color-text-tertiary);
    transform: scale3d(1, 1, 1);
  }
}
