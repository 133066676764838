@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  align-items: flex-start;
  display: flex;
  min-height: $header-height;
  padding: 0 $spacing-small;
  width: 100%;
}

.kiosk {
  align-items: flex-start;
}

.home {
  align-items: center;
}

.language {
  flex: 2;
}

.language > div {
  width: 12.5rem;
}

@media screen and (max-width: 1125px) {
  .language > div {
    width: 8.75rem;
  }
}

.headerBrandedLogoImage {
  display: block;
  margin-left: 16px;
  max-height: 47px;
}

[dir='rtl'] .headerBrandedLogoImage {
  margin-left: 0;
  margin-right: 16px;
}

.branded {
  justify-content: space-between;
}

@include respond-to(handhelds-tablets) {
  .root {
    justify-content: center;
  }
}

@media screen and (max-width: 780px) {
  .root {
    padding: 0;
  }

  .language > div {
    width: 100%;
  }
}
