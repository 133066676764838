@import '../../scss/settings';

.root {
  background-color: var(--color-bg-navigation);
  bottom: 0;
  box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.12);
  left: 0;
  position: fixed;
  width: 100%;
  z-index: $z-index-4--submit;
  border-radius: var(--spacing-08) var(--spacing-08) 0 0;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: slideInUpInfo;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes slideInUpInfo {
  from {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.overlay {
  background-color: var(--color-bg-overlay);
  height: 100vh;
  left: 0;
  opacity: 0.4;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $z-index-3--submit-overlay;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInOverlay;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.4;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16);
  padding-top: var(--spacing-40);
}

.content {
  text-align: center;
  margin-bottom: var(--spacing-08);
}

.content p {
  margin-top: 0;
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-08);
}

.title {
  font-size: var(--font-size-lg);
  color: var(--color-text-primary);
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.actions,
.loader {
  text-align: center;
  margin-bottom: var(--spacing-32);
}

.loader {
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing-24);
}

.submit {
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: var(--spacing-24);
}

.submit button {
  width: 100%;
  max-width: 24rem;
  justify-content: center;
}

.cancel {
  color: var(--color-text-tertiary);
  margin-bottom: var(--spacing-24);
  text-decoration: underline;
}

.preview {
  font-size: var(--font-size-xs);
  display: block;
  margin-top: var(--spacing-08);
  font-weight: var(--font-weight-bold);
}

p.warning {
  color: var(--color-status-negative);
  font-weight: bold;
  font-size: var(--font-size-md);
}

.privacy {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
  text-align: center;
  margin: 0;
}

.privacy a {
  color: var(--color-text-tertiary);
  text-decoration: underline;
}
