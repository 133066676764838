@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

$kiosk-input-height: 72px;
$kiosk-code-length: 10;

.root {
  min-height: 180px;
  position: relative;
}

.form {
  position: relative;
}

.formTitle {
  color: var(--color-text-secondary);
  font-size: var(--font-size-lg);
  text-align: center;
}

.input {
  font-size: em(22px);
  height: $kiosk-input-height;
  line-height: 1;

  position: relative;
  resize: none;
  width: 100%;
}

.form {
  align-items: flex-start;
  display: flex;
  max-width: 80vw;
  min-width: 60vw;
}

.formCol {
  flex: 1;
  margin-inline-end: var(--spacing-16);
}

.formCol:last-child {
  margin-inline-end: 0;
}

.button {
  position: absolute;
  z-index: -1;
  right: 46px;
  top: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $kiosk-input-height;
  width: $kiosk-input-height;
  margin-left: $spacing;
  opacity: 0;
  transition:
    opacity 0.4s,
    transform 0.3s;
  /* simulate a primary bedrock button */
  background-color: var(--color-btn--primary-bg);
  color: var(--color-btn--primary-text);
  border-radius: var(--border-radius-base);
}

.button:hover {
  background-color: var(--color-btn--primary-hover-bg);
}

.valid .button {
  opacity: 1;
  transform: translate3d(130px, 0, 0);
  z-index: auto;
}

.button:disabled {
  opacity: 0.3;
}

.arrow {
  height: 32px;
  width: 32px;
}

.errorMessage {
  color: var(--color-status-negative);
  composes: fadeIn from 'sass-loader!../../scss/_animations.scss';
  font-size: var(--font-size-md);
  margin: $spacing auto 0;
  max-width: 46px * $kiosk-code-length;
  min-width: 416px;
  text-align: center;
  width: 100%;
}

@include respond-to(handhelds) {
  .root {
    min-height: 0;
  }

  .button {
    padding: 0;
    right: -40px;
    top: 41px;
    width: calc($kiosk-input-height / 2);
  }

  .valid .button {
    border-radius: 0 3px 3px 0;
    opacity: 1;
    transform: translate3d(40px, 0, 0);
    z-index: auto;
  }

  .input {
    width: 100%;
  }

  .errorMessage {
    max-width: 26px * $kiosk-code-length;
    font-size: var(--font-size-base);
    min-width: 0;
  }

  .formTitle {
    font-size: var(--font-size-large);
  }
}

@include respond-to(handhelds-tablets) {
  .form {
    display: block;
  }

  .button {
    margin: 0;
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }

  .formCol {
    margin-inline: 0 !important;
    margin-bottom: var(--spacing-16);
  }

  .valid .button {
    transform: translate3d(0, 0, 0);
  }
}
