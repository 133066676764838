@import '../../../scss/settings';

.root {
  position: relative;
  width: 100%;
  max-width: 32.5rem;
}

.root h3 {
  font-size: var(--font-size-md);
  margin-bottom: var(--spacing-08);
}

.root p {
  margin-top: 0;
  margin-bottom: var(--spacing-08);
}

.close {
  position: absolute;
  top: var(--spacing-08);
  right: var(--spacing-16);
  display: flex;
}
