@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';

.group {
  align-items: center;
  border-radius: 9999px;
  border: 1px solid var(--color-controls-border);
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  font-weight: var(--font-weight-bold);
  margin-top: 11px;
  outline: none;
}

.group.animate {
  transition: opacity 0.5s;
  transition-delay: 0.15s;
  opacity: 0;
}

.button {
  padding: 0;
  margin: 0;
  height: 0; /* aspect ratio hack */
  padding-top: calc(100% / 11); /* aspect ratio hack */
  position: relative;
  background-color: transparent;
  color: var(--color-text-primary);
  display: flex;
  flex: 1;
  transition:
    background-color 0.35s,
    color 0.35s,
    box-shadow 0.35s;
  border: 0;
  cursor: pointer;
}

.button .buttonInner {
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  font-weight: bold;
  font-size: var(--font-size-sm);
}

.button:not(:last-child) {
  margin-inline-end: 1px;
}

.button:focus {
  box-shadow: none !important;
}

.button:hover .buttonInner {
  background-color: var(--color-controls-hover-bg);
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-controls--selected-marker);
}

.button:focus .buttonInner {
  background-color: var(--color-controls-hover-bg);
  box-shadow: none;
  border-width: 0;
}

.button:focus:hover .buttonInner {
  background-color: var(--color-controls--selected-bg);
  color: var(--color-text-tertiary);
}

.button:focus .buttonInner:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  pointer-events: none;
  border-radius: 9999px;
  box-shadow: var(--focus-base);
  outline: 2px solid transparent;
  border: 1px solid var(--color-controls-active-border);
}

.button:active .buttonInner:after {
  content: none;
}

.button.checked .buttonInner,
.button:active .buttonInner {
  background-color: var(--color-controls--selected-marker);
  color: var(--color-text-inverted);
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.25);
}

@include respond-to(handhelds-tablets) {
  .group {
    user-select: none;
  }
}
