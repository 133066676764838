@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';

$clock-size: 42px;

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $clock-size;
  justify-content: center;
  width: $clock-size;
}

.bar {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

:global .CircularProgressbar .CircularProgressbar-path {
  stroke-linecap: butt;
  stroke: var(--color-controls--selected-bg);
  stroke-width: 6px !important;
}

:global .CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--color-controls--selected-marker);
}

@include respond-to(handhelds-tablets) {
  .root {
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .bar {
    display: none;
  }
}
