@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';

.root {
  margin-bottom: var(--spacing-16);
}

.item {
  display: flex;
  margin-bottom: var(--spacing-16);
  align-items: center;
}

.item svg {
  margin-inline-end: var(--spacing-08);
  width: 2rem;
  height: 2rem;
  color: var(--color-icon-decorative);
}

@include respond-to(handhelds-tablets) {
  .item {
    justify-content: left;
  }
}
