@import '../../../../../scss/settings';
@import '../../../../../scss/mixins/respond-to';

.attributes {
  font-weight: var(--font-weight-bold);
}

.footer * + * {
  margin-inline-start: var(--spacing-16);
}
