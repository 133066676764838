@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.container {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.buttonPreviousContainer button {
  margin-bottom: 40px;
}

.logoContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline-end: 50px;
}

.logo {
  display: block;
  max-width: 300px;
}

.form {
  display: flex;
  flex: 0 0 370px;
  flex-direction: column;
  margin-left: 50px;
}

.footer {
  bottom: 0;
  left: 0;
  position: fixed;
}

@include respond-to(handhelds-tablets) {
  .root {
    display: block;
    height: auto;
    padding: 60px 0;
    width: 100%;
  }

  .container {
    flex-direction: column;
  }

  .form {
    flex: 2;
    margin: 0;
  }

  .footer {
    display: none;
  }

  .logoContainer {
    margin-inline-end: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .logo {
    max-width: 72px;
  }

  .buttonPreviousContainer {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-1--question-previous;
  }

  .buttonPreviousContainer button {
    width: 100%;
    border-radius: 0;
    justify-content: center;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}
