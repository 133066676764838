@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  padding-top: 8px;
  width: 100%;
}

.root.preview {
  padding-bottom: 60px;
}

.withBanner {
  padding-top: 68px;
}

.header {
  left: 0;
  position: absolute;
  top: 28px;
  z-index: $z-index-2--kiosk-header;
}

[dir='rtl'] .header {
  left: auto;
  right: 0;
}

.withBanner .header {
  top: 83px;
}

.form {
  padding: 20px;
}

@include respond-to(handhelds-tablets) {
  .root {
    height: 100vh;
    overflow-y: auto;
  }

  .withBanner {
    padding-top: 8px;
  }

  .header {
    display: none;
  }

  .withBanner .header {
    top: 0;
  }
}
