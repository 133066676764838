@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

$bullet-size: 4px;
$current-bullet-size: 20px;
$current-bullet-size-mobile: 12px;
$hover-bullet-size: 32px;
$progress-bar-width: 8px;

.root {
  animation-delay: 0.3s;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInRight;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  right: 30px;
  top: 0;
  width: $progress-bar-width;
  z-index: $z-index-1--progress-bar;
}

[dir='rtl'] .root {
  animation-name: bounceInLeft;
  left: 30px;
  right: auto;
}

.bar {
  border-radius: 10px;
  box-shadow: 0 0 0 1px var(--color-border);
  flex: 2;
  margin-top: 28px;
  position: relative;
  width: 100%;
}

.time {
  flex: 0 0 72px;
  position: relative;
}

.background {
  background-color: var(--color-graph-bg);
  border-radius: 10px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: max-height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  z-index: -1;
}

.items {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.item,
.current {
  transition:
    width 0.35s,
    background-color 0.35s;
}

.itemWrapper {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  width: 100%;
}

.item.itemTablet {
  display: none;
}

.item.itemDesktop {
  display: block;
}

.item:focus,
.item:focus-visible {
  box-shadow: none;
  outline: none;
}

.item:focus-visible .bullet {
  box-shadow: var(--focus-base);
}

.current {
  cursor: default;
}

.bullet {
  background-color: var(--color-text-hint);
  border-radius: 100%;
  height: $bullet-size;
  left: 50%;
  margin-left: calc($bullet-size / -2);
  margin-top: calc($bullet-size / -2);
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transition: all 0.15s ease-in;
  width: $bullet-size;
}

.bullet:hover {
  z-index: $z-index-1--progress-bar + 2;
}

.current .bullet {
  animation: pulse 0.6s forwards cubic-bezier(0.215, 0.61, 0.355, 1);
  height: $current-bullet-size;
  width: $current-bullet-size;
  margin-left: calc($current-bullet-size / -2);
  margin-top: calc($current-bullet-size / -2);
  background-color: var(--color-graph-bg) !important;
  border: 5px solid var(--color-controls--selected-marker);
  transition: none;
  z-index: $z-index-1--progress-bar + 1;
}

.item.answered .bullet {
  background-color: var(--color-controls--selected-marker);
}

.item.answered.muted .bullet {
  background-color: var(--color-text-hint);
}

.item.answered.muted:hover .bullet,
.item.answered.muted:focus-visible .bullet {
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-border);
  color: var(--color-text-primary);
}

// The last active/clickable question
// Happens when the user goes back to a previous question, without any score or skipping
.item:not(.current):not(.answered):not(.muted):hover .bullet,
.item:not(.current):not(.answered):not(.muted):focus-visible .bullet {
  height: $current-bullet-size;
  width: $current-bullet-size;
  margin-left: calc($current-bullet-size / -2);
  margin-top: calc($current-bullet-size / -2);
  background-color: var(--color-graph-bg) !important;
  border: 5px solid var(--color-controls--selected-marker);
}

.item:not(.current):not(.answered):not(.muted):focus-visible .bullet {
  transition: none;
  z-index: $z-index-1--progress-bar + 1;
  box-shadow: var(--focus-base);
}

.unknown {
  cursor: not-allowed !important;
}

.scale {
  font-weight: var(--font-weight-bolder);
  font-size: em(16px);
  line-height: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.item.answered:hover .bullet,
.item.answered:focus-visible .bullet {
  height: $hover-bullet-size;
  margin-left: calc($hover-bullet-size / -2);
  margin-top: calc($hover-bullet-size / -2);
  width: $hover-bullet-size;
}

.bullet {
  align-items: center;
  color: var(--color-text-inverted);
  display: flex;
  justify-content: center;
}

.item.answered:hover .scale,
.item.answered:focus-visible .scale {
  opacity: 1;
}

.tooltip {
  opacity: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate3d(100vw, 0, 0);
  width: 222px;
  z-index: -1;
}

[dir='rtl'] .tooltip {
  left: 30px;
  right: auto;
}

.item.answered:hover .tooltip,
.item.answered:focus-visible .tooltip {
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-name: slideIn;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transform-origin: top right;
  z-index: $z-index-1--progress-bar + 3;
}

[dir='rtl'] .item.answered:hover .tooltip,
[dir='rtl'] .item.answered:focus-visible .tooltip {
  animation-name: slideInLeft;
  transform-origin: top left;
}

@include respond-to(handhelds-tablets) {
  .root,
  .items {
    flex-direction: row;
  }

  .root {
    align-items: flex-start;
    animation: none;
    background-color: var(--color-graph-bg);
    border-bottom: 1px solid var(--color-border);
    height: 20px;
    pointer-events: none;
    right: 0;
    touch-action: none;
    width: 100%;
  }

  [dir='rtl'] .root {
    left: 0;
    right: auto;
  }

  .items {
    align-items: center;
  }

  .item.itemTablet {
    display: block;
  }

  .item.itemDesktop {
    display: none;
  }

  .bar,
  .background {
    border-radius: 0;
  }

  .bar {
    border-right: 1px solid var(--color-border);
    box-shadow: none;
    height: 100%;
    margin-top: 0;
  }

  [dir='rtl'] .bar {
    border-left: 1px solid var(--color-border);
    border-right: 0;
  }

  .background {
    transition: max-width 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 100%;
  }

  .time {
    flex: 0 0 50px;
    height: 100%;
  }

  .current .bullet {
    height: $current-bullet-size-mobile;
    margin-left: calc($current-bullet-size-mobile / -2);
    margin-top: calc($current-bullet-size-mobile / -2);
    width: $current-bullet-size-mobile;
    background-color: var(--color-graph-bg) !important;
    border: 3px solid var(--color-controls--selected-marker);
  }

  .item.answered:hover .bullet,
  .item.answered:focus-visible .bullet {
    height: $bullet-size;
    width: $bullet-size;
  }

  .scale {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1190px) {
  [dir='rtl'] .root {
    left: 0;
  }

  .bar,
  .background {
    border-radius: 0;
  }

  .bar {
    margin-top: 0;
  }

  .time {
    display: none;
  }
}

@keyframes pulse {
  from {
    transform: scale3d(0.01, 0.01, 0.01);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-10px, 0, 0);
  }

  75% {
    transform: translate3d(5px, 0, 0);
  }

  90% {
    transform: translate3d(-3px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(5%, -13px, 0);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, -13px, 0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-5%, -13px, 0);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, -13px, 0);
  }
}
