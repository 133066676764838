@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.skippingModalHeader {
  align-items: center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInUpSkip;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  display: flex;
  margin-bottom: 22px;
  margin-top: -45px;
}

.skippingModalHeader h2 {
  margin-bottom: 0;
}

.hasReason .skippingModalHeader {
  animation: none;
}

.title {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
}

.cancel {
  color: var(--color-text-tertiary);
  margin-inline-start: var(--spacing-16);
  text-decoration: underline;
}

.actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  grid-row-gap: 0.5rem;
}

.actions > button {
  margin-inline-end: var(--spacing-08);
}

.actionsSkipped {
  align-items: flex-start;
}

.buttonReasonWrapper {
  margin-inline-end: 0.5rem;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInUpSkip;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transform: translate3d(0, 100%, 0);
}

.hasReason .buttonReasonWrapper {
  animation-duration: 0s !important;
  animation-delay: 0s !important;
}

.buttonReasonWrapper:nth-child(0) {
  animation-delay: 0s;
}

.buttonReasonWrapper:nth-child(1) {
  animation-delay: 0.1s;
}

.buttonReasonWrapper:nth-child(2) {
  animation-delay: 0.2s;
}

.buttonReasonWrapper:nth-child(3) {
  animation-delay: 0.3s;
}

.buttonReasonWrapper:nth-child(4) {
  animation-delay: 0.4s;
}

.commentTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.commentTitle span {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bold);
  padding: var(--spacing-24) var(--spacing-16);
  position: relative;
  text-align: center;
  background: var(--color-bg-card);
}

.commentTitle:before {
  content: '';
  position: absolute;
  border-top: 1px solid var(--color-border);
  left: 0;
  width: 100%;
}

@include respond-to(handhelds-tablets) {
  .skippingModal {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: slideInUpSkip;
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    background-color: var(--color-bg-card);
    bottom: 0;
    box-shadow:
      0 -2px 62px 0 rgba(0, 0, 0, 0.31),
      inset 0 1px 0 0 rgba(0, 0, 0, 0.09);
    left: 0;
    padding: $spacing;
    position: absolute;
    width: 100%;
    z-index: $z-index-2--question-skip;
  }

  .skippingModal.hasReason {
    animation: none;
    background-color: transparent;
    bottom: auto;
    box-shadow: none;
    left: auto;
    padding: $spacing 0;
    position: relative;
  }

  .skippingModalHeader {
    animation: none;
    margin-top: 0;
  }

  .hasReason .skippingModalHeader {
    margin-bottom: var(--spacing-16);
  }

  .actions {
    flex-direction: column;
    margin-bottom: 0;
  }

  .actions > button:not(:last-child) {
    margin-inline-end: 0;
    margin-bottom: var(--spacing-08);
  }

  .actionsSkipped {
    flex-direction: row;
    padding-bottom: var(--spacing-32);
    align-items: center;
  }

  .buttonReasonWrapper {
    animation: none;
    transform: none;
    width: 100%;
  }

  .buttonReasonWrapper button {
    width: 100%;
    margin-bottom: var(--spacing-08);
    justify-content: center;
  }

  .buttonReasonWrapper:last-child button {
    margin-bottom: 0;
  }

  .commentButtonWrapper button {
    width: 100%;
    justify-content: center;
  }

  .buttonReasonWrapper,
  .cancel {
    flex: 1;
  }

  .hasReason .actionsSkipped > button:last-of-type {
    display: none;
  }

  .cancelHeader {
    text-align: right;
  }
}

@keyframes slideInUpSkip {
  from {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
