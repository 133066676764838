@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.root {
  composes: fadeIn from 'sass-loader!../../scss/_animations.scss';
  height: 100%;
  max-width: 700px;
  padding: 70px 0;
}

.thankYouWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
}

.content {
  width: calc(100% - 320px);
}

.kiosk .content {
  text-align: center;
  width: 100%;
}

.title {
  composes: title from 'sass-loader!../../scss/components/_title.scss';
  margin-bottom: 19px;
}

.kiosk .title {
  font-weight: var(--font-weight-bold);
  font-size: em(35px);
  font-weight: normal;
}

.text {
  font-size: var(--font-size-large);

  > span {
    white-space: nowrap;
  }
}

.kiosk .text {
  font-size: em(25px);
}

.kioskInfo {
  color: var(--color-text-tertiary);
  font-size: em(25px);
  margin-top: 160px;
  text-align: center;
}

.logoWrapper {
  margin-top: 30px;
}

.kiosk .logoWrapper {
  margin: 0 0 60px;
}

@include respond-to(handhelds-tablets) {
  .root {
    max-width: 100%;
    padding: 0 20px;
    text-align: center;
  }

  .wrapper {
    flex-direction: column;
  }

  .content {
    width: 100%;
  }
}
