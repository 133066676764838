@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.textarea {
  display: block;
  max-height: 200px;
  min-height: 53px;
  resize: none;
  margin-bottom: var(--spacing-08);
}

.questionContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 36px;
}

.info {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-08);
}

@include respond-to(handhelds-tablets) {
  .textarea {
    font-size: em(16px); // http://stackoverflow.com/a/6394497/338762
    max-height: none;
    min-height: 90px;
  }

  .focused .container {
    margin: 0;
  }

  .focused .info {
    font-weight: var(--font-weight-bolder);
  }

  .focused .textareaContainer {
    height: 100%;
    position: relative;
  }

  .focused .questionContainer {
    display: flex;
    margin-right: 0;
    text-align: left;
  }

  .focused .textarea {
    border: 0;
    box-shadow: none;
    display: block;
    height: 100%;
    margin: 0 -20px;
    width: 100vw;

    .focused:focus {
      background-color: transparent;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  .focused .questionContainer {
    display: none !important;
  }
}
