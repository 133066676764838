@import '../../../scss/settings';
@import '../../../scss/mixins/em';

.root {
  background-color: var(--color-bg-navigation);
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  cursor: text;
  font-size: em(12px);
  line-height: 1.2;
  padding: 10px;
  width: 100%;
}

.triangle {
  color: var(--color-bg-navigation);
  position: absolute;
  right: -8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
  top: 5px;
  transform: scaleX(0.7);
}

[dir='rtl'] .triangle {
  left: -8px;
  right: auto;
  transform: scaleX(-1);
}

.section {
  border-top: 1px solid var(--color-border);
  margin: 10px 0 0;
  padding: 10px 0 0;
}

.title {
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-h6);
  margin-bottom: 2px;
}

.value {
  color: var(--color-status-highlight);
  font-weight: var(--font-weight-bold);
}
