.enter,
.appear {
  transform: translate3d(-100%, 0, 0);
}

.enter.enterActive,
.appear.appearActive {
  transform: translate3d(0, 0, 0);
  transition: transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.leave.leaveActive {
  transform: translate3d(-150%, 0, 0);
  transition: transform 0.2s ease-out;
}
