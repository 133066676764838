@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  left: 0;
  position: absolute;
  top: 28px;
  z-index: $z-index-2--kiosk-header;
}

.form {
  align-items: center;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.logo {
  margin-bottom: 60px;
}

.contextName {
  font-weight: var(--font-weight-bold);
  font-size: em(40px);
  font-weight: normal;
}

.noSurvey {
  padding: 0 20px;
  text-align: center;
}

.noSurveyTitle {
  color: var(--color-status-highlight);
  font-weight: var(--font-weight-bolder);
  font-size: em(35px);
  margin-bottom: 20px;
}

.noSurveyText {
  font-size: em(25px);
}

.formToggler {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-size-large);
  margin: 70px 0;
  text-align: center;
}

.formToggler a {
  color: var(--color-text-primary);
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 1128px) {
  .header {
    display: flex;
    position: initial;
    width: 100%;

    > div {
      align-items: center;
    }
  }
}

@include respond-to(handhelds) {
  .form {
    justify-content: flex-start;
    padding-top: 80px;
  }

  .contextName {
    font-size: em(25px);
  }
}
