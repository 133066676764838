@import '_settings';
@import 'mixins/respond-to';
@import 'mixins/em';
@import 'mixins/rem';

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

:global .survey-app {
  height: 100%;
}

body {
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--color-bg-survey);
  color: var(--color-text-primary);
  font-family: var(--font-family-base);
  line-height: var(--line-height-base);
  position: relative;
}

:global .u-visible-mobile {
  display: none !important;

  @include respond-to(handhelds-tablets) {
    display: block !important;
  }
}

:global .u-hidden-mobile {
  display: block !important;

  @include respond-to(handhelds-tablets) {
    display: none !important;
  }
}
