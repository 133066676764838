@import '../../scss/settings';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help {
  margin-top: var(--spacing-08);
  display: block;
  color: var(--color-text-secondary);
  font-size: var(--font-size-sm);
  text-align: center;
}
