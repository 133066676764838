@import '../../../scss/settings';
@import '../../../scss/mixins/respond-to';

.root {
  display: flex;
  box-sizing: border-box;
  padding: var(--spacing-16);
  align-items: center;
  border: 1px solid var(--color-border);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-inline-end: var(--spacing-16);
  flex-shrink: 0;
  color: var(--color-icon-decorative);
}

.icon svg {
  width: inherit;
  height: inherit;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.content .text .title {
  margin-bottom: var(--spacing-04);
  font-size: var(--font-size-md);
}

.content .text {
  flex: 1;
  margin-inline-end: var(--spacing-16);
}

.content .text p {
  margin: 0;
}

@include respond-to(handhelds) {
  .icon {
    width: 2rem;
    height: 2rem;
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .content .text {
    margin-inline-end: 0;
  }

  .content .text h3 {
    margin-bottom: var(--spacing-04);
  }

  .content .action {
    margin-top: var(--spacing-08);
  }
}
