@import '../../scss/settings';

.root {
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-32);
}

.anonymity {
  color: var(--color-text-primary);
  margin-top: var(--spacing-32);
}

.frequency {
  margin-bottom: var(--spacing-32);
}
