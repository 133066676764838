.content {
  white-space: pre-wrap;
}

.attributes {
  font-weight: var(--font-weight-bold);
}

.contentUrl {
  text-decoration: underline;
}

.footer > :not(:last-child) {
  margin-inline-end: var(--spacing-16);
}
