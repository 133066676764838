@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.sidebar {
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  flex: 0 0 450px;
  justify-content: center;
  padding-right: 20px;
}

.illustration {
  display: block;
  height: auto;
  width: 450px;
}

.content {
  display: flex;
  flex: 0 0 $content-width;
  flex-direction: column;
}

.previous {
  align-self: flex-start;
  flex: 0 0 $header-height;
  padding-top: 28px;
}

.previousFixed {
  left: 0;
  padding-top: 0;
  position: fixed;
  top: 20px;
  width: 100%;
  z-index: $z-index-1--question-previous;
}

.previousFixed button {
  width: 100%;
  border-radius: 0;
  justify-content: center;
  border-left: 0;
  border-top: 0;
  border-right: 0;
}

.question {
  backface-visibility: hidden;
  align-items: flex-start;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}

.questionContent {
  padding-inline-end: 20px;
}

.valueCard {
  padding-right: 163px;
}

.questionContent {
  max-width: $content-width;
  min-height: 230px;
  width: 100%;
}

.text {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-32);
}

.valueText {
  font-size: var(--font-size-lg);
  color: var(--color-text-tertiary);
  margin-bottom: var(--spacing-24);
  font-weight: var(--font-weight-bold);
}

.action {
  margin-top: 66px;
  width: 100%;
}

.actionFixed {
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  width: 100%;
  z-index: $z-index-1--question-action;
}

.actionFixed > button {
  display: flex;
  width: 100%;
  border-radius: 0;
  justify-content: center;
}

@include respond-to(handhelds-tablets) {
  .root {
    display: block;
    height: 100%;
    padding: 0 20px;
    width: 100%;

    &.hasReason {
      overflow-y: auto;
    }
  }

  .container {
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 0;
  }

  .hasReason .container {
    align-items: flex-start;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    padding: 90px 0;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .sidebar {
    display: block;
    flex: 0 0 auto;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .illustration {
    height: 200px;
    margin: 0 auto;
    width: auto;
  }

  .valueIcon {
    display: none;
  }

  .valueCard {
    padding-right: 0;
  }

  .content {
    display: block;
    flex: 0 0 auto;
    text-align: center;
    width: 100%;
  }

  .questionContent {
    max-width: 100%;
    min-height: 240px;
  }

  .questionContent {
    padding-inline-end: 0;
  }

  .question {
    flex: 0 0 auto;
    justify-content: flex-start;
    padding: 0;
  }

  .text {
    font-size: var(--font-size-md);
  }

  .valueText {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-16);
  }

  .root.focused {
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $z-index-4--comment-focused;
  }

  .focused .actionFixed,
  .focused .previousFixed,
  .focused .illustration,
  .focused .valueCard {
    display: none !important;
  }

  .focused .container {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: var(--spacing-08);
  }

  .focused .text {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    justify-content: center;
  }

  .focused .focusedScore {
    align-items: center;
    background-color: var(--color-controls--selected-marker);
    border-radius: 100%;
    color: var(--color-text-inverted);
    display: flex;
    flex: 0 0 2rem;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
    height: 2rem;
    width: 2rem;
    justify-content: center;
    line-height: 1;
    margin-inline-start: var(--spacing-08);
  }

  .focused .questionContent {
    padding-top: 20px;
  }
}

@keyframes fadeInFocused {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-height: 620px) and (max-width: 991px) {
  .container {
    padding-top: 90px !important;
  }

  .focused .container {
    padding: 0 !important;
  }
}

@media screen and (max-height: 500px) {
  .mutedText {
    margin-bottom: 10px;
  }

  .container {
    padding-top: 0 !important;
  }

  .sidebar {
    display: none;
  }
}

@media screen and (max-height: 568px) {
  .root {
    display: flex;
    height: auto;
    justify-content: center;
    min-height: 100vh;
    overflow-y: visible;
  }

  .container {
    height: auto;
    margin: 0;
    padding: 90px 0 60px;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }

  .content {
    display: block;
  }

  .focused .container {
    padding: 0 !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1128px) {
  .sidebar {
    flex: 0 0 320px;
  }

  .illustration {
    width: 320px;
  }
}

@media screen and (max-width: $screen-md) and (orientation: landscape) {
  .questionContent {
    margin-top: 80px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  .focused .text {
    display: none !important;
  }

  .focused .questionContent {
    padding-top: 0 !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
  .root.focused {
    overflow: auto;
  }

  .focused .question {
    justify-content: flex-start;
  }

  .focused .sidebar {
    align-items: flex-start;
    padding-top: 80px;
  }

  .focused .text {
    font-size: var(--font-size-large);
    margin-bottom: 10px;
  }

  .focusedScore {
    display: none;
  }
}
