@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.root {
  bottom: 20px;
  left: 20px;
  max-width: 400px;
  position: fixed;
  z-index: $z-index-7--notification;

  @include respond-to(handhelds-tablets) {
    max-width: 80vw;
  }
}

.content {
  width: calc(100% - 40px);
}
