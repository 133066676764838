@import '../../scss/settings';

.enterNext,
.enterPrevious {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transform-style: preserve-3d;
}

.enterNext {
  animation-name: enterNext;
}

.enterPrevious {
  animation-name: enterPrevious;
}

@keyframes enterNext {
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 200px) rotateX(-90deg);
  }

  to {
    opacity: 1;
    position: relative;
    transform: translate3d(0, 0, 0) rotateX(0);
    z-index: 1;
  }
}

@keyframes enterPrevious {
  from {
    opacity: 0;
    transform: translate3d(0, -200px, -200px) rotateX(90deg);
  }

  to {
    opacity: 1;
    position: relative;
    transform: translate3d(0, 0, 0) rotateX(0);
    z-index: 1;
  }
}
