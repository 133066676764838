@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  align-items: center;
  display: flex;
  min-height: $footer-height;
  padding: var(--spacing-16);
  width: 100%;
  display: flex;
}

.links {
  color: var(--color-text-secondary);
}

.privacy {
  color: var(--color-text-secondary);
  margin-inline-start: var(--spacing-16);
  text-decoration: underline;
}

@include respond-to(handhelds-tablets) {
  .root {
    justify-content: center;
    margin-left: 0;
  }
}
