@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.root {
  position: relative;
  width: 100%;
}

.loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  min-height: 230px;
  text-align: center;
  width: 100%;
}

.header {
  left: 0;
  position: absolute;
  top: 28px;
  z-index: $z-index-2--kiosk-header;
}

[dir='rtl'] .header {
  left: auto;
  right: 0;
}

.thankYou .header {
  top: 83px;
}

.attributesForm {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 120px 0 0;
}

.thankYou .attributesForm {
  padding-top: 200px;
}

@media screen and (max-width: 1128px) {
  .root.noIllustrations {
    padding-left: 120px;
  }
}

@include respond-to(handhelds-tablets) {
  .root.noIllustrations {
    padding-left: 0;
  }

  .root {
    overflow-y: scroll;
  }

  .header {
    display: none;
  }

  .loader {
    max-width: 100%;
    min-height: 240px;
  }

  .attributesForm {
    display: block;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
    width: 100%;
  }
}

// ensure spacing around fixed "previous" and "continue" buttons on mobile
@include respond-to(handhelds) {
  .root {
    margin-block-start: 10vh;
    margin-block-end: 10vh;
  }
}
