@import '../../scss/settings';
@import '../../scss/mixins/respond-to';

.ephemeral {
  max-width: $content-width;
  padding: 0 20px;
  width: 100%;
}

.title {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-32);
}

.footer {
  bottom: 0;
  left: 0;
  position: fixed;
}

@include respond-to(tablets) {
  .ephemeral {
    padding: 0;
  }
}
