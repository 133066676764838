@import '../../scss/settings';

.rawDataExportContainer {
  color: #6b6e73;
  font-size: var(--font-size-base);
  line-height: 1.82;
  margin-top: 30px;
}

.rawDataExportContainer p,
.rawDataExportContainer span {
  overflow-wrap: break-word;
  font-size: var(--font-size-base);
}
