@import '../../scss/settings';
@import '../../scss/mixins/respond-to';
@import '../../scss/mixins/em';

.info {
  align-items: center;
  display: flex;
}

.fixed {
  position: fixed;
  right: 81px;
  top: 35px;
  z-index: $z-index-1--kiosk-info;
}

[dir='rtl'] .info {
  left: 81px;
  right: auto;
}

.code {
  background-color: var(--color-controls--disabled-bg);
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: var(--font-size-md);
  font-weight: bold;
  padding: 4px 10px;
  margin-inline-end: var(--spacing-16);
}

@include respond-to(handhelds-tablets) {
  .info {
    display: none;
  }
}
